.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #0073e6;
  padding: 15px 30px;
  position: fixed;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.5s ease;
  max-width: 90%; /* Contain header within 90% of screen width */
  margin: 0 auto; /* Center header */
}

.dark-mode .header {
  background-color: #004080;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
}

.header-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header-button {
  background-color: #0059b3;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  margin: 0 5px;
  transition: all 0.3s ease;
  transform: scale(1);
}

.header-button:hover {
  background-color: #0066cc;
  transform: scale(1.05);
}

/* Theme toggle button */
.theme-toggle-button {
  background-color: transparent;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  padding: 8px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
}

.theme-toggle-button:hover {
  transform: rotate(90deg) scale(1.1);
}

.theme-icon {
  display: inline-block;
  font-size: 24px;
}

.dark-mode .theme-toggle-button {
  color: #b3e0ff;
}

/* Footer styles */
.footer {
  background-color: #003366; 
  color: #ffffff; 
  padding: 20px;
  text-align: center;
}

.dark-mode .footer {
  background-color: #001a33; 
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.footer-logo {
  max-width: 100px; 
  height: auto; 
  object-fit: contain; 
}

.footer-text {
  font-size: 14px;
}

.footer-links {
  margin-top: 10px;
}

.footer-link {
  color: #b3e0ff; 
  text-decoration: none;
  font-size: 14px;
  margin: 0 10px;
}

.footer-link:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .header {
    flex-direction: column;
    align-items: center;
  }

  .header-buttons {
    flex-direction: column;
    gap: 5px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@media (max-width: 600px) {
  div {
    flex-direction: column;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Poppins', sans-serif; /* Specific font for paragraph elements */
}

h1 {
  text-align: center;
  color: #333;
  font-family: 'Poppins', sans-serif; /* Specific font for paragraph elements */
}


h1alt {
  font-size: 24pt;
  color: lightgray;
  font-weight: bold;
  text-align: center;
}

h1rev {
  font-size: 24pt;
  text-align: center;
  color: #e2dddd;
  font-family: 'Poppins', sans-serif; /* Specific font for paragraph elements */
}

/* Styles for file input and selected file name */
input[type="file"] {
  display: none;
}
p.file-name {
  margin-top: 10px;
}

/* Styles for select dropdown */
label {
  display: block;
  margin-top: 10px;
}
select {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 5px;
}

/* Styles for encryption section */
.encryption-section {
  margin-top: 20px;
}

/* Styles for encryption key input */
input[type="text"] {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 5px;
}

/* Styles for dropzone */
.dropzone {
  outline: 2px dashed khaki;
  background: khaki;
  padding: 20px;
  text-align: center;
}

/* Styles for encrypted data textarea */
textarea {
  width: 100%;
  height: 150px;
  margin-top: 10px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Styles for decrypt section */
.decrypt-section {
  margin-top: 20px;
}

/* Styles for download button */
button {
  margin-top: 10px;
  padding: 8px 16px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* CSS for full-width responsive image */
.full-width-image {
  width: 100%;
  height: auto;
  display: block;
}


@media (max-width: 480px) {

  .footer {
    padding: 10px;
    text-align: center;
  }

  .footer-content img {
    max-width: 150px;
  }
}


@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');